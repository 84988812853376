<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("redeem_success_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('redeem_success_page.form_desc')"
    ></div>
    <!-- <div  class="page__content" v-if="getModuleConfig('redeem_success_page.content')" v-html="getModuleConfig('redeem_success_page.content')"></div> -->

    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <div v-else-if="!showLoading">
        <div
          v-html="getModuleConfig('redeem_success_page.content')"
          class="p-4 shadow-lg rounded redeem--content"
        ></div>
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('redeem_success_page.button_text') ||
        getModuleConfig('redeem_success_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <MemberButton
        :button-text="getModuleConfig('redeem_success_page.button_text')"
        :button-url="getModuleConfig('redeem_success_page.button_url')"
        :type="getModuleConfig('redeem_success_page.button_action')"
      ></MemberButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import couponMixin from "@/mixins/liff/coupon";

export default {
  mixins: [couponMixin],
  components: { Loading, MemberButton },
  data() {
    return {
      showLoading: false,
    };
  },

  computed: {},
  mounted() {},

  methods: {},
};
</script>

<style lang="scss">
.redeem {
  &--content {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
